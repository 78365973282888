<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loading || loadingParent">
			<v-skeleton-loader key="transitionCard" type="card" class="rounded-xl" />
		</template>
		<template v-else>
			<v-card key="transitionEnded" class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>
					<span>{{ $t('profile.knownLanguages') }}</span>
					<v-spacer />
					<CVLanguagesAdd :loading-parent="loading || loadingParent" />
				</v-card-title>
				<v-card-text>
					<v-list>
						<v-list-item two-line v-for="language in languages" :key="`language${language.id}`">
							<v-list-item-content>
								<v-list-item-title>
									{{ language.name }}
								</v-list-item-title>
								<v-list-item-subtitle>{{ language.level.name }}</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<CVLanguagesEdit :loading-parent="loading || loadingParent" :language="language" />
							</v-list-item-action>
							<v-list-item-action>
								<v-btn icon color="red" @click="callRemoveLanguage(language)">
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CVLanguages',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		CVLanguagesAdd: () => import('@/components/cv/CVLanguagesAdd.vue'),
		CVLanguagesEdit: () => import('@/components/cv/CVLanguagesEdit.vue')
	},
	computed: {
		...mapGetters({
			languages: 'cv/languages',
			user: 'users/user'
		})
	},
	created() {
		this.loading = true
		this.fetchLanguages(this.user.id).then(() => {
			this.loading = false
		})
	},
	methods: {
		callRemoveLanguage(language) {
			this.loading = true
			this.removeLanguage({
				userID: this.user.id,
				languageID: language.id
			}).then(() => {
				this.loading = false
			})
		},
		...mapActions('cv', ['fetchLanguages', 'removeLanguage'])
	}
}
</script>
